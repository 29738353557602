import '../styles/blog-post.scss';

import { Button } from 'antd';
import { Link } from 'gatsby';
import React from 'react';

import BlogPostItem from '../components/blog-post-item';
import Boilerplate from '../components/boilerplate';
import SectionHeading from '../components/common/section-heading';
import Image from '../components/image';
import { Box, Col, Container, Row, Section } from '../components/layout';
import { useLinkResolver } from '../hooks/use-link-resolver';
import { BlogPostTemplateContext } from '../types/blog';
import { StaticPage } from '../types/pages';
import { Template } from '../types/templates';
import { formatBlogPostDate } from '../utils/blog';
import { contentfulJSONToReactComponents } from '../utils/contentful';

const BlogPostTemplate: Template<BlogPostTemplateContext> = ({
  pageContext: { id, data, previous, next },
}) => {
  const linkResolver = useLinkResolver();

  return (
    <Boilerplate seoId={data.seo.id} pageId={id}>
      <Section className="blog__hero-section">
        <Container>
          <SectionHeading title="Blog" />
        </Container>
      </Section>

      <Section className="blog-post__heading-section">
        <Container>
          <time>{formatBlogPostDate(data.date)}</time>
          <h2>{data.title}</h2>

          <Box
            className="blog-post__image-container"
            style={{
              sm: { borderRadius: '2rem', height: '20rem' },
              md: { borderRadius: '3rem', height: '30rem' },
              lg: { borderRadius: '3.5rem', height: '35rem' },
            }}
          >
            <Image id={data.image.id} />
          </Box>
        </Container>
      </Section>

      <Section className="blog-post__content-section">
        <Container>
          {contentfulJSONToReactComponents(data.content.json)}
        </Container>
      </Section>

      <Section className="blog-post__footer-section">
        <Container>
          <Box direction="column" align="middle">
            {/* TODO move this translation to contentful */}
            <SectionHeading title="Więcej artykułów" />

            <Row style={{ width: '100%', marginTop: '1rem' }}>
              {[previous, next].map((post, index) => {
                if (post === undefined) {
                  return <></>;
                }

                return (
                  <Col span={24} key={index}>
                    <BlogPostItem item={post} />
                  </Col>
                );
              })}
            </Row>

            <Link to={linkResolver(StaticPage.Blog)}>
              {/* TODO move this translation to contentful */}
              <Button type="primary">Zobacz wszystkie</Button>
            </Link>
          </Box>
        </Container>
      </Section>
    </Boilerplate>
  );
};

export default BlogPostTemplate;
